<template>
  <div class="item d-flex flex-column">
    <div v-if="item.img || img" ref="image" class="item__image">
      <img
        ref="img"
        loading="lazy"
        width="496"
        height="660"
        :src="img ? img : item.img"
        :alt="item.title"
      />
    </div>
    <div
      v-if="item.title"
      ref="title"
      class="h3 item__title"
      v-html="item.title"
    />
    <div v-if="item.text" ref="text" class="item__text" v-html="item.text" />
    <div v-if="item.link" ref="more" class="item__more">
      <a class="more-link"> Подробнее </a>
    </div>
    <router-link class="stretched-link" :to="{ name: item.link }" />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    img: {
      type: String,
      required: false,
    },
  },
  mounted() {
    if (!is_touch()) {
      gsap.from(
        this.item.name === "Kids"
          ? gsap.utils.toArray([
              this.$refs.title,
              this.$refs.text,
              this.$refs.more,
              this.$refs.image,
            ])
          : gsap.utils.toArray([
              this.$refs.image,
              this.$refs.title,
              this.$refs.text,
              this.$refs.more,
            ]),
        {
          y: 100,
          stagger: 0.1,
          scrollTrigger: {
            trigger: this.$el,
            start: this.item.name === "Kids" ? "top bottom" : "top 80%",
            end: this.item.name === "Kids" ? "center center" : "bottom center",
            scrub: 1,
          },
        }
      );

      gsap.from(this.$refs.img, {
        scale: 1.2,
        scrollTrigger: {
          trigger: this.$refs.img,
          start: "top 80%",
          end: "center center",
          scrub: 1,
        },
      });
    }
  },
};
</script>

<style scoped>
.item {
  position: relative;
}

.item__image {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  padding-bottom: 130%;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__title {
  margin-bottom: 22px;
  line-height: 0.9;
}

.item__more {
  margin-top: 23px;
}

.item-entertainment {
  margin-top: -305px;
}

body.-notouch .item:hover .more-link {
  color: #205640;
}
@media (max-width: 1279px) {
  .item-entertainment {
    margin-top: -130px;
  }
}
@media (max-width: 991px) {
  .item-entertainment {
    margin-top: -60px;
  }
}
@media (max-width: 767px) {
  .item-entertainment {
    margin-top: 0;
  }
}

.item-parking {
  margin-bottom: 190px;
}
@media (max-width: 1279px) {
  .item-parking {
    margin-bottom: 140px;
  }
}

.item-kids {
  margin-top: 300px;
}
@media (min-width: 768px) {
  .item-kids .item__image {
    order: 999;
    margin: 40px 0 0;
  }
}
@media (max-width: 1279px) {
  .item-kids {
    margin-top: 250px;
  }
}
@media (max-width: 991px) {
  .item-kids {
    margin-top: 150px;
  }
}

@media (max-width: 767px) {
  .item {
    margin: 0 0 50px;
  }

  .item__image {
    margin-bottom: 25px;
  }

  .item__title {
    margin-bottom: 17px;
    line-height: 1;
  }

  .item__more {
    margin-top: 15px;
  }
}
</style>
